
import { apisetMealLogLists } from '@/api/shop'
import LsDialog from '@/components/ls-dialog.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    LsDialog,
  }
})
export default class HistoryMeal extends Vue {

  @Prop({ default: '' }) id!: string

  /** S Data **/
  historyMeal: any = []
  /** E Data **/

  /** S Methods **/
  // 获取团购列表
  async getShopLists () {
    this.historyMeal = await apisetMealLogLists({id: this.id})
  }

  handleOpen (): void {
    this.getShopLists()
  }
  /** E Methods **/
}
